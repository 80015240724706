.frame{
    display: flex;
    margin-bottom: 20px;
}

.imgStyle{
    padding: 3px;
    border-radius: 6px;
    box-shadow: 0px 0px 5px black;
    width: 325px;
}

.imgPortico{
    padding: 3px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px black;
    height: 300px;
}

.inActive{
    padding: 3px;
    border-radius: 20px;
    width: 325px;
    opacity: 0.7;
    z-index: 1;
    left:0;
    position: absolute;
}

.typeBoxContainer{
    margin:20px;
    font-family: 'Saira', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.typeTitle{
    margin: 20px;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    letter-spacing: 0.1em;
    position: relative;
    color: #4B4440;
}



