.img {
    position: absolute;
    top: 0;
    left: -136px;
    z-index: -100;
}
.container {
    position: relative;
    margin-top: 20px;
}
