.img {
    position: absolute;
    top: 0;
    left: -80px;
    z-index: -100;
}

.imgRS {
    position: absolute;
    top: 0;
    left: -80px;
    z-index: -100;
}

.img2 {
    left: -120px;
}

.img3 {
    left: -110px;
}

.img4 {
    zoom:2;
}

.container {
    position: relative;
    margin-top: 20px;
}

.containerImg {
    position: relative;
    width: 100%;
    height: auto;
    z-index: -1
}

@media screen and (min-width: 900px) {
    .container {
        zoom:1.6;
    }
}

.value {
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    background-color: #638594;
    padding: 0 2px;
    color: white;
    border-radius: 1px;
    font-family: 'Saira', sans-serif;
}

.t1 {
    top: 58px;
    left: 40px;
}

.d1 {
    top: 160.5px;
    left: 6px;
    width: 52px;
}

.l1 {
    top: 110px;
    left: 165px;
}

.t2 {
    top: 100px;
    left: 50px;
}

.d2 {
    top: 238px;
    left: 6px;
    width: 52px;
}
.dRS{
    top: 458px;
    left: -20px;
    width: 52px;
}

.l2 {
    top: 185px;
    left: 150px;
}

.t3 {
    top: 170.5px;
    left: 40px;
}

.d3 {
    top: 337px;
    left: -10px;
    width: 52px;
}

.l3 {
    top: 280px;
    left: 120px;
}

.t4 {
    top: 240.5px;
    left: 53px;
}
.d4 {
    top: 485px;
    left: -7px;
    width: 52px;
}
.l4 {
    top: 413px;
    left: 160px;
}

.t5 {
    top: 170.5px;
    left: 40px;
}

.d5 {
    top: 280.5px;
    left: 0;
    width: 52px;
}

.l5 {
    top: 233px;
    left: 120px;
    min-width: 38px;
}

.b5 {
    top: 207px;
    left: 20px;
}

.t6 {
    top: 210.5px;
    left: 40px;
}

.d6 {
    top: 342px;
    left: -7px;
    width: 52px;
}

.l6 {
    top: 297px;
    left: 130px;
}

.b6 {
    top: 245px;
    left: 0;
}

.t7 {
    top: 220.5px;
    left: 40px;
}

.d7 {
    top: 359px;
    left: -7px;
    width: 52px;
}

.l7 {
    top: 313px;
    left: 130px;
}

.b7 {
    top: 265px;
    left: 0;
}
.t8 {
    top: 250.5px;
    left: 40px;
}

.d8 {
    top: 401px;
    left: -12px;
    width: 52px;
}

.l8 {
    top: 355px;
    left: 130px;
}

.b8 {
    top: 305px;
    left: 0;
}
.b8TopConnection {
    top: 345px;
    left: 0;
}
