.inputLine{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 5px 10px;
    width:100%;
}
.input{
    width: 50px;
    border: 1px solid #92B6C7;
    border-radius: 4px;
    margin: 0;
    font-family: 'Saira', sans-serif;
    text-align: center;
}

.inputTotal{
    display: flex;
    justify-content: space-between;
    width: 75%;
}
