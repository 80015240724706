.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Saira', sans-serif;
    width: 100%;
}

.boxHeader {
    background-color: #92B6C7;
    border-radius: 3px 3px 0 0;
    font-weight: 600;
    text-align: center;
    color: black;
}

.boxContainer {
    border: 2px solid #92B6C7;
    border-radius: 6px;
    width: 325px;
    margin-top: 20px;
}

.orange {
    color: #FF8001;
}

.red {
    color: rgba(255, 6, 30, .87);
}

.blue {
    color: #2591FF;
}
.black{
    color: black;
}

.boxContainerMini {
    margin: 20px 2px 0;
    border-radius: 6px;
    width: 161.5px;
    background-color: #4D5B71;
}

.boxCalculate {
    border: 0;
}

.possible {
    background-color: #D93829;
    color: white;
    margin: 20px;
    border: 1px solid #92B6C7;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
}

.dimensionContainer {
    display: flex;
    font-family: 'Saira', sans-serif;
    background-color: #4D5B71;
    color: white;
    font-weight: 500;
}

.dimensionSubContainer {
    width: 33.333%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6px;
}

.decoupleContainer {
    display: flex;
    text-align: center;
    flex-direction: column;
    background-color: #4D5B71;
}

.decoupleElem {
    margin: 10px;
    display: flex;
    font-size: 11px;
    font-weight: 600;
    color: white;
    justify-content: space-around;
    letter-spacing: -0.02em;
}

.line {
    width: 90%;
    border-bottom: 1px solid white;
    margin: auto;
}

.lowerIntendContainer {
    display: flex;
    justify-content: space-around;
    color: white;
    font-family: 'Saira', sans-serif;
    font-size: 15px;
    text-align: center;
    font-weight: 600;
}

.kitContainer {
    display: flex;
    justify-content: space-around;
    color: white;
    font-family: 'Saira', sans-serif;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
}
