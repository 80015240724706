.all {
    position: absolute;
    font-size: 8px;
    font-weight: 600;
    opacity: 0.9;
}

.containerImg {
    position: relative;
    margin-bottom: 420px;
    margin-top: 20px;
    zoom: 1;
}

@media screen and (min-width: 900px) {
    .containerImg {
        zoom: 2.2;
    }
}

.img {
    position: absolute;
    top: 0;
    left: -175px;
    z-index: -100;
}

.indigo {
    color: #3C0E7A;
}

.orange {
    color: #FF8001;
}

.red {
    color: #FF061E;
}

.green {
    color: #068306;

}

.blue {
    color: #2591FF;
}

.cg {
    top: 356.1px;
    left: -28px;
}

.cd {
    top: 356.1px;
    left: 34px;
}

.av {
    top: 214px;
    left: -99px;
}

.bv {
    top: 213.8px;
    left: 100px;
    width: 50px;
}

.y {
    top: 101px;
    left: -167.8px;
    transform: rotate(-90deg);
    width: 0;
    font-size: 9px;
}

.ar {
    top: 34px;
    left: -71px;
}

.br {
    top: 34px;
    left: 53px;
    width: 50px;
}


.dog {
    top: 388.8px;
    left: -102px;
}

.lo {
    top: 388.8px;
    left: -1px;
}

.dod {
    top: 388.8px;
    left: 109px;
}

.width {
    top: 14px;
    left: 18px;
    font-size: 9px;
    width: 0;
}

.height {
    top: 169px;
    left: 158.9px;
    transform: rotate(-90deg);
    font-size: 9px;
    width: 0;
}

.totalW {
    top: -3.2px;
    left: 25px;
    font-size: 9px;
    width: 0;
}

.totalH {
    top: 157px;
    left: 175.2px;
    transform: rotate(-90deg);
    font-size: 9px;
    width: 0;
}
