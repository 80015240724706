
.container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    align-content: center;
}

.subBoxContainer {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
}

.boxForm {
    display: flex;
    border: 2px solid #758D9A;
    padding: 30px 20px 20px;
    margin: 0 10px 10px;
    border-radius: 6px;
    flex-direction: column;
    align-items: center;
    box-shadow: 5px 5px 10px #535477;
    position: relative;
    width: 325px;
    font-family: 'Saira', sans-serif;
}

.dropSelect {
    padding: 5px;
    border-radius: 4px;
    min-width: 16px;
}

.boxForm_Middle {
    display: flex;
    padding: 25px 10px;
    margin: 0 10px 10px;
    border-radius: 6px;
    flex-direction: column;
    font-weight: 500;
    align-items: center;
    box-shadow: 5px 5px 10px #4D5B71;
    position: relative;
    max-width: 325px;
    width: 380px;
    background-color: #4D5B71;
    color: white;
    font-family: 'Saira', sans-serif;
    font-size: 0.8rem;;
}

.boxForm_Final {
    display: flex;
    padding: 25px 10px;
    margin: 10px;
    border-radius: 6px;
    flex-direction: column;
    font-weight: 500;
    align-items: center;
    box-shadow: 5px 5px 10px #4D5B71;
    position: relative;
    max-width: 325px;
    width: 380px;
    background-color: #4D5B71;
    color: white;
    font-family: 'Saira', sans-serif;
    font-size: 0.8rem;
}

.boxForm_Final_elem {
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding: 5px;
    font-size: 12px;
}
.boxForm_Final_elem_alert {
    padding-top: 5px;
    font-size: 10px;
}

.boxForm_Title {
    background-color: #A5BECD;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    width: 100%;
    height: auto;
    font-weight: 600;
    border-radius: 4px 4px 0 0;
    font-size: 1rem;
    padding-top: 5px;
    color: black;
}

.boxForm_Title_text {
    position: static;
    margin-top: -4px;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
}

.boxForm_Body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.headerContainer {
    display: flex;
    justify-content: space-around;
}

.addingButton {
    position: relative;
    width: auto;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.addingButton img {
    position: relative;
    width: 25px;
    margin: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.containerAnchor {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerPortico {
    justify-content: center;
    width: 350px;
}

.w350 {
    width: 350px;
}

.count {
    font-size: 10px;
    margin-right: 2px;
    font-weight: 600;
}

.mySelector {
    padding: 0;
    font-family: 'Saira', sans-serif;
    min-width: 116px;
}

.ffSerif {
    font-family: 'Saira', sans-serif !important;
}

.addingButton .addingButton_name {
    position: relative;
    width: auto;
    margin: 10px;
    border-radius: 50%;
}

.delButton {
    height: 17px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: -3px;
    top: 35px;
}

.inputCounter {
    margin: 10px;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 20px;
    border: 2px solid black;
    outline: none;
    width: 77px;
    height: 33px;
    transition: .2s;
    text-align: center;
}

.allMass {
    border: 2px solid black;
    height: 25px;
    width: auto;
    border-radius: 10px;
    align-items: center;
    padding: 2px;
}

.massBox {
    height: 25px;
    width: 120px;
    align-items: center;
    padding: 2px;
}

.stabBox {
    height: 25px;
    align-self: center;
}

.showParams {
    margin: 5px;
    border: 1px solid #92B6C7;
    border-radius: 5px;
    padding: 5px;
}

.showMiddleParams {
    border-bottom: 1px solid white;
    display: flex;
    justify-content: center;
    width: 100%;
}

.showFinalParams {
    display: flex;
    justify-content: center;
    width: 100%;
}

.showStabilizer {
    margin-right: 20px;
    display: flex;
}

.stabilizer {
    border: 2px solid black;
    padding: 2px;
    margin: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.maxW100 {
    max-width: 100%;
}

.m20 {
    margin: 20px;
}

.margin015 {
    margin: 0 15px;

}

.typeStabilisationBy {
    display: flex;
    justify-content: space-between;
}

.possible {
    background-color: #D93829;
    color: white;
    margin: 20px;
    border: 1px solid #92B6C7;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
}

.result3th {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
}

.mh45mw80 {
    max-height: 45px !important;
    min-width: 80px !important;
    margin-left: 0 !important;
}

.regWind {
    display: flex;
    margin-top: 20px;
    font-size: 12px;
    max-width: 100%;
    text-align: center;
}

.regWindContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.w50 {
    width: 50px !important;
}

.regWindDepartment {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 215px;
}

.regCheckContainer {
    width: 75px;
    margin-left: 113px;
    display: flex;
    cursor: pointer;
}

.regCheckInput {
    margin: 6px;
    cursor: pointer;
}

.regWindResult {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mt25 {
    margin-top: 25px;
}

.m5 {
    margin: 5px;
}

.disFlex {
    display: flex;
}

.ml5 {
    margin-left: 5px;
}

.textR {
    text-align: right;
}

.line {
    width: 90%;
    border-bottom: 1px solid white;
}

.porticoContainer {
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.blockDHSelect {
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: space-between;
}

.blockDHContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-size: 12px;
    max-width: 100%;
    width: 100%;
}



