.option{
    border-radius: 10px;
    margin: 10px;
}

.select{
    border-radius: 10px;
    padding: 5px;
    margin: 10px;
    width: 195px;
    font-family: 'Saira', sans-serif;
}

.select:hover {
    background-color: #F1F2F4;
    color: black;
}

.showBlock{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: auto;
}

.counterInput{
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 10px;
    display: block;
    padding: 6px 0 7px!important;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    letter-spacing: inherit;
    animation-duration: 10ms;
}
.selectG{
color: white!important;
    text-decoration: none!important;
}
.selectG:after{

}
.selectList{
    display: block;
    text-align: center;
}
.optionStab{
    border-radius: 6px;
    padding: 2px;
    border:2px solid #A5BECD;
    cursor: pointer;
    color: black;
    text-align: center;
    font-weight: 600;
    font-family: 'Saira', sans-serif;
}

.active{
    background-color: #A5BECD;
}

.typeContainer{
    margin: 20px;
    font-family: 'Saira', sans-serif;
}

.typeContainer__Title{
    margin: 20px;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 17px;
    letter-spacing: 0.08em;
    position: relative;
    text-align: center;
}
.typeContainer__Body{
    display: flex;
    justify-content: space-around;
}
